<template>
<div class="examType">
  <a-spin :indicator="indicator" :spinning="spinning">
  <a-button type="primary" @click="()=>this.addShow = true">新增</a-button>
  <a-table :columns="columns" style="min-height: 500px" :data-source="InfoTable" rowKey="id"   :pagination="false">
    <span slot="examType" slot-scope="text,item,index">
            <span v-for="list in item.member_job_title">{{list + ';'}}</span>
            </span>
    <span slot="open" slot-scope="text,item,index">
              {{item.is_open === 1 ? '开':'关'}}
            </span>
    <span slot="operate" slot-scope="text,item,index">
              <a style="margin-right: 10px" @click="editBtn(item.id,item)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
  </a-table>
  <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
    <a-pagination v-model="pagination.current"
                  :total="pagination.total"
                  @change="paginationChange" />
  </div>


  <a-modal v-model="addShow" title="新增" @ok="addOk">
    <a-form-model  :rules="rules"  :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
      <a-form-model-item  label="名称" prop="name">
        <a-input placeholder="输入分类名称" v-model="addForm.name"/>
      </a-form-model-item>
      <a-form-model-item  label="身份" prop="member_type">
        <a-select v-model="addForm.member_type"  placeholder="选择身份" >
          <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.label" @click="typeChange(list.value)">
            {{ list.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    <a-form-model-item label="职称" prop="member_job_title">
      <a-select
          placeholder="选择职称"
          v-model="addForm.member_job_title"
          mode="multiple"
          :maxTagCount= 3
          showArrow
          :maxTagTextLength = 7
      >
        <a-select-option v-for="item in titleInfo" :key="item.id" :value="item.job_title">
          {{item.job_title}}
        </a-select-option>
      </a-select>
    </a-form-model-item>
      <a-form-model-item label="默认显示" prop="is_open">
        <a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
  <a-modal v-model="editShow" title="新增" @ok="editOk">
    <a-form-model  :rules="rules"  :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
      <a-form-model-item  label="名称" prop="name">
        <a-input placeholder="输入分类名称" v-model="editForm.name"/>
      </a-form-model-item>
      <a-form-model-item  label="身份" prop="member_type">
        <a-select v-model="editForm.member_type"  placeholder="选择身份" >
          <a-select-option v-for="list in typeInfo" :key="list.value" :value="list.label" @click="typeChange(list.value)">
            {{ list.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="职称" prop="member_job_title">
        <a-select
            placeholder="选择职称"
            v-model="editForm.member_job_title"
            mode="multiple"
            :maxTagCount= 3
            showArrow
            :maxTagTextLength = 7
        >
          <a-select-option v-for="item in titleInfo" :key="item.id" :value="item.job_title">
            {{item.job_title}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="默认显示" prop="is_open" >
        <a-switch :checked="editForm.is_open!==0"   @click="openEdit(editForm.is_open === 1? 0 : 1)"
                  checked-children="是" un-checked-children="否"  />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
  </a-spin>
</div>
</template>

<script>
import {
  delExamType,
  getExamType,
  getTitleId,
  getTypes,
  postExamType,
  putExamOpen,
  putExamType,
} from "@/service/titleExam_api";

export default {
  name: "examType",
  data(){
    return{
      addShow:false,
      editShow:false,
      spinning:false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      addForm:{
        is_open:0
      },
      editForm:{},
      InfoTable:[],
      titleInfo:[],
      typeInfo:[{
        value:1001,
        label:'医生'
      },
        {
          value:1002,
          label:'护士'
        },
        {
          value:1003,
          label:'医技'
        },
        {
          value:1004,
          label:'药师'
        },
        {
          value:1005,
          label:'医学生'
        },
        {
          value:1006,
          label:'其他医务从业者'
        },
        {
          value:1007,
          label:'非医务从业者'
        },],
      rules:{
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        member_type:[{ required: true, message: '请选择身份', trigger: 'blur' }],
        member_job_title:[{required:true,message:'请输入职称',trigger: 'blur'}],
        is_open:[{ required: true, message: '是否开启', trigger: 'change' }]
      },
      labelCol: { span: 4},
      wrapperCol: { span: 16 },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '分类名称',
          dataIndex: 'name',
        },
        {
          title: '关联职称',
          scopedSlots: { customRender: 'examType' }
        },
        {
          title: '身份',
          dataIndex: 'member_type'
        },
        {
          title: '默认显示',
          scopedSlots: { customRender: 'open' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_time'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' }
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"职称考试分类管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
  },
  mounted() {
    this.getTitleExamType()
  },
  methods:{
    async getTitleExamType(pageNum){
      // this.spinning =true
      const exam = await getExamType(pageNum)
      if (exam.code === 0){
        this.InfoTable = exam.data.etypes
        this.pagination.total = exam.data.count
      }
      // this.spinning =false
    },
    paginationChange(e){
      this.getTitleExamType(e)
    },
    openWitch(checked){
      if (checked === false){
        this.addForm.is_open = 0
      }else {
        this.addForm.is_open = 1
      }
    },
    //选择身份
    async typeChange(type){
      const Info =await getTypes(type)
      this.titleInfo = Info.data.row
    },
    //新增
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const add =await postExamType(this.user_name,this.addForm)
          if (add.code === 0){
            await this.getTitleExamType(this.pagination.current)
            this.$message.success('添加成功')
            this.$refs.addItem.resetFields();
            this.addShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
     editBtn(id,ref){
      // const edit =await  getTitleId(id)
      // this.editForm = edit.data
      let arr = JSON.parse(JSON.stringify(ref))
      this.editForm = {
        name : arr.name,
        is_open : arr.is_open,
        member_job_title : arr.member_job_title,
        member_type : arr.member_type
      }
      this.editId = id
      this.editShow = true
    },
     openEdit(open){
      this.editForm.is_open = open
    },
    //修改
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const edit = await putExamType(this.editId,this.editForm)
          switch (this.editForm.is_open){
            case 1 :
              await putExamOpen(this.editId)
          }
          if (edit.code === 0){
            await this.getTitleExamType(this.pagination.current)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //删除职称考试分类
    async delTitle(id){
      const Info =await delExamType(id)
      if (Info.code === 0){
        await this.getTitleExamType(this.pagination.current)
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
